<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '7975a153-4ace-4620-8efa-36d0aa5b8ff9'
    }
  }
}
</script>
